import axios from "axios";


export default axios.create({
  baseURL: "https://tpm-api.myorder.direct",
  headers: {
    "Content-type": "application/json",
    "CUID" : "5adb13c1-c9f2-11ed-a4f6-00163cd1cd8e",
    "STORE_NAME" : "TPM Packaging Solutions",
    "STORE_CONTACT" : "(03) 9543 3100",
    "STORE_URL" : "https://tpm.myorder.direct/",
    "SHOP_TYPE" : "retail"
  }
});