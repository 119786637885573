import {
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Icon,
    Button,
    Heading,
    Text,
    InputGroup,
    InputLeftElement,
    Divider,
    Container
  } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUser } from '../../Helpers/Queries';
import { CustomToast } from '../../Helpers/AddToast'
import { MdAccountCircle, MdAppSettingsAlt, MdHouse, MdOutlineArrowLeft, MdSaveAlt } from 'react-icons/md';
import { FaBuilding, FaLock, FaPhone, FaReceipt, FaRegEnvelope, FaRegUser } from 'react-icons/fa';
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';


const CreateAccount = (props) => {
  const { addToast } = CustomToast();
  const navigate = useNavigate()
  const [address, setAddress] = useState({})
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [company, setCompany] = useState("")
  const [abn, setAbn] = useState("")
  const [password, setPassword] = useState("")
  const [password2, setPassword2] = useState("")

  const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  const checkLength = (value) => value.length >= 3;
  const checkPhone = (number) => /^\d{10}$/i.test(number);
  const checkAbn = (number) => /^\d{11}$/i.test(number);

  const mutation = useMutation(createUser, {
    onSuccess: (res) => {
        if(res.data.error){
            addToast({title: "Oh No!", message: res.data.message, type: "error"})
        } else {
            addToast({title: "Success!", message: res.data.message, type: "success", duration: 30000})
            navigate('/login')
        }
    },
    onError: (err) => {
        addToast({title: "Oh No!", message: (err.response.data.message) ? err.response.data.message : "An error occured, please try again.", type: "error"})
    }
  })

  function parseAddress(results){
    let address_components = results['address_components']
    let unit = ""
    let street_number = ""
    let street_name = ""
    let suburb = ""
    let state = ""
    let country = ""
    let postcode = ""
    address_components.forEach(component => {
        if(component['types'][0] === 'subpremise') unit = component['long_name'];
        if(component['types'][0] === 'street_number') street_number = component['long_name'];
        if(component['types'][0] === 'route') street_name = component['long_name'];
        if(component['types'][0] === 'locality') suburb = component['long_name'];
        if(component['types'][0] === 'administrative_area_level_1') state = component['long_name'];
        if(component['types'][0] === 'country') country = component['long_name'];
        if(component['types'][0] === 'postal_code') postcode = component['long_name'];
    });
    let newaddress = [{unit:unit, street_number:street_number, street_name:street_name, suburb:suburb, state:state, country:country, postcode:postcode}]
    setAddress(newaddress)
  }

  function processUser(){
    if(!firstname || !lastname || !email || !phone || !company || !password || !password2 || !address.length){
      addToast({title: "Ooops!", message: "Please make sure you fill in all required fields", type: "info"})
      return
    }
    
    if(!isEmail(email)) {
      addToast({title: "Ooops!", message: "Incorrect formatted email address, please try again", type: "info"})
      return
    }

    if(!checkLength(firstname)) {
      addToast({title: "Ooops!", message: "First name needs to be more than 3 characters, please try again", type: "info"})
      return
    }

    if(!checkLength(lastname)) {
      addToast({title: "Ooops!", message: "Last name needs to be more than 3 characters, please try again", type: "info"})
      return
    }

    if(!checkPhone(phone)) {
      addToast({title: "Ooops!", message: "Phone number is incorrectly formatted, please try again", type: "info"})
      return
    }

    if(abn && !checkAbn(abn)) {
      addToast({title: "Ooops!", message: "ABN should be 11 numbers with no spaces, please try again", type: "info"})
      return
    }

    if(!checkLength(company)) {
      addToast({title: "Ooops!", message: "Last name needs to be more than 3 characters, please try again", type: "info"})
      return
    }

    if(password !== password2){
      addToast({title: "Ooops!", message: "Your password do not match, please try again", type: "info"})
      return
    }

    if(password.length <= 5){
      addToast({title: "Ooops!", message: "Your password must be at least 6 characters long, please try again", type: "info"})
      return
    }

    if(!/[A-Z]/.test(password)){
      addToast({title: "Ooops!", message: "Your password must contain an uppercase letter, please try again", type: "info"})
      return
    }

    if(!/[0-9]/.test(password)){
      addToast({title: "Ooops!", message: "Your password must contain at least one number, please try again", type: "info"})
      return
    }
    mutation.mutate({firstname, lastname, email, phone, company, password, password2, address, abn});
  }

  return (<>
    <Container maxW="container.md" mt={5} color='black'>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'} textAlign={'center'} color={'brand.400'}>Interested in browsing our products?</Heading>
          <Text fontSize={'lg'} textAlign={'center'} color={'gray.600'}>
            No problem, just enter your details below to create an account, and one of our friendly team will contact you regarding access.
          </Text>
        </Stack>
        
      <Box backgroundColor={'white'} rounded={10} 
          boxShadow={'lg'} my={5} p={5} bg='white'>
      <Heading as="h4" size="md">
          <Icon as={MdAccountCircle} color="secondary.inputHelper" /> Personal Details
      </Heading>
    <form>
      <Box bg="secondary.card" rounded="lg" p={5}>
        <Stack spacing={4} marginBottom="1rem">
          <FormControl isRequired>
            <FormLabel htmlFor="firstname">First Name</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon as={FaRegUser} color="secondary.inputHelper" />
                }
              />
              <Input
                focusBorderColor="main.500"
                type="text"
                name="firstname"
                id="firstname"
                value={firstname}
                onChange={e=>setFirstname(e.target.value)}
              />
            </InputGroup>
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="lastname">Last Name</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon as={FaRegUser} color="secondary.inputHelper" />
                }
              />
              <Input
                focusBorderColor="main.500"
                type="text"
                name="lastname"
                id="lastname"
                value={lastname}
                onChange={e=>setLastname(e.target.value)}
              />
            </InputGroup>
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="email">Email Address <small>(This will update your login to the website)</small></FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon
                    as={FaRegEnvelope}
                    color="secondary.inputHelper"
                  />
                }
              />
              <Input
                focusBorderColor="main.500"
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={e=>setEmail(e.target.value)}
              />
            </InputGroup>
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="contact">Contact Number <small>(e.g 0422123123 or 0354123123)</small></FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon
                    as={FaPhone}
                    color="secondary.inputHelper"
                  />
                }
              />
              <Input
                focusBorderColor="main.500"
                type="number"
                maxLength="10"
                minLength="10"
                min="0100000000"
                max="0900000000"
                name="contact"
                id="contact"
                value={phone}
                onChange={e=>setPhone(e.target.value)}
              />
            </InputGroup>
          </FormControl>
        </Stack>
      </Box>
      <Divider
      marginTop="0"
      marginBottom="2rem"
      orientation="horizontal"
    />
    <Heading as="h4" size="md">
        <Icon as={MdHouse} color="secondary.inputHelper" /> Address Details
    </Heading>
      <Box bg="secondary.card" rounded="lg" p={5}>
        <Stack spacing={4} marginBottom="1rem">
          <FormControl isRequired>
            <FormLabel htmlFor="companyname">Company Name</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon as={FaBuilding} color="secondary.inputHelper" />
                }
              />
              <Input
                focusBorderColor="main.500"
                type="text"
                name="companyname"
                id="companyname"
                value={company}
                onChange={e=>setCompany(e.target.value)}
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="abn">ABN</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon as={FaReceipt} color="secondary.inputHelper" />
                }
              />
              <Input
                focusBorderColor="main.500"
                type="number"
                name="abn"
                id="abn"
                maxLength="11"
                minLength="11"
                max="99999999999"
                value={abn}
                onChange={e=>setAbn(e.target.value)}
              />
            </InputGroup>
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="address">Address</FormLabel>
              <GooglePlacesAutocomplete
                apiKey='AIzaSyCBu3FBwXeJcQYgOIRIWb27O8RDb6UUZ-Q'
                autocompletionRequest={{
                  componentRestrictions: {
                  country: ['au'],
                  }
                }}
                selectProps={{
                  placeholder: "Start typing to find your address...",

                  onChange: (description) => (
                    geocodeByAddress(description.label).then(results => {
                        parseAddress(results[0]);
                    })
                  ),
                }}
              />
          </FormControl>
        </Stack>
      </Box>
      <Divider
      marginTop="0"
      marginBottom="2rem"
      orientation="horizontal"
    />
      <Stack spacing={0} marginTop={2}>
        <Heading as="h4" size="md">
            <Icon as={MdAppSettingsAlt} color="secondary.inputHelper" /> Security settings
        </Heading>
        <Text color="gray.500" fontSize="md">
          <small>Password should be at least 6 characters in length and should include at least one upper case letter, and one number.</small>
        </Text>
      </Stack>

      <Box bg="secondary.card" rounded="lg" p={5}>
        <Stack spacing={4} marginBottom="1rem">
          <FormControl isRequired>
            <Stack justifyContent="space-between" isInline>
              <FormLabel htmlFor="new_password">Password</FormLabel>
            </Stack>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon as={FaLock} color="secondary.inputHelper" />
                }
              />
              <Input
                focusBorderColor="main.500"
                name="new_password"
                id="new_password"
                type="password"
                placeholder="Enter your password"
                autoComplete="new-password"
                value={password}
                onChange={e=>setPassword(e.target.value)}
              />
            </InputGroup>
          </FormControl>
          <FormControl isRequired>
            <Stack justifyContent="space-between" isInline>
              <FormLabel htmlFor="new_password2">
                Confirm Password
              </FormLabel>
            </Stack>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon as={FaLock} color="secondary.inputHelper" />
                }
              />
              <Input
                focusBorderColor="main.500"
                name="new_password2"
                id="new_password2"
                type="password"
                placeholder="Confirm your password"
                autoComplete="new-password"
                value={password2}
                onChange={e=>setPassword2(e.target.value)}
              />
            </InputGroup>
          </FormControl>
        </Stack>
        <Stack
        direction={["column", "row"]}
        spacing="1rem"
        justify="end"
        marginTop="2rem"
      >
        <Button onClick={()=>navigate("/")} disabled={mutation.isLoading} leftIcon={<Icon fontSize={'lg'} as={MdOutlineArrowLeft} />} type="button" colorScheme="blue">
          Go Back to Login
        </Button>
        <Button onClick={()=>processUser()} isLoading={mutation.isLoading} leftIcon={<Icon fontSize={'lg'} as={MdSaveAlt} />} type="button" colorScheme="blue">
          Register Account
        </Button>
      </Stack>
      </Box>
    </form>
    </Box>
      </Container>
      </>);
  }

export default CreateAccount