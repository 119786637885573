import { Stack, Text, VStack, HStack } from "@chakra-ui/react"

const DesktopPrice = (props) => {
    const { product, addToCart } = props
    return(
        <>
        <HStack spacing={1} display="flex" alignItems="center" mt={2}>
            <Stack flex={1} justify={'flex-start'} direction={'row'} spacing={6}>
                <VStack spacing={0}>
                    <Stack flex={1} justify={'flex-start'} direction={'row'} spacing={0}>
                        {
                            (product.gst === "1")
                                ? 
                                    <Text color="black" fontWeight="bold" fontSize="4xl">${parseFloat(product.sellinc-(product.sellinc/11)).toFixed(2)}</Text>
                                :
                                    <Text color="black" fontWeight="bold" fontSize="4xl">${parseFloat(product.sellinc).toFixed(2)}</Text>
                        }
                    </Stack>
                    <Stack flex={1} justify={'flex-start'} direction={'row'} spacing={0}>
                        {
                            (product.gst === "1")
                                ? 
                                <Text color="black" fontSize="sm">{"( $"+parseFloat(parseFloat(product.sellinc-(product.sellinc/11))/parseFloat(product.cartonqty)).toFixed(2)+" / ea )"}</Text>
                                :
                                <Text color="black" fontSize="sm">{"( $"+parseFloat(parseFloat(product.sellinc)/parseFloat(product.cartonqty)).toFixed(2)+" / ea )"}</Text>
                        }
                        
                    </Stack>
                </VStack>
                <Stack flex={1} alignItems={'center'} justify={'flex-end'} direction={'row'} spacing={6}>
                    {addToCart}                  
                </Stack>
            </Stack>
        </HStack>
        </>
    )
}

export default DesktopPrice