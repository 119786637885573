import { useEffect } from 'react'
import { Container, Flex, Progress, SimpleGrid, VStack, } from '@chakra-ui/react';
import TileLayout from '../ProductTile/TileLayout';
import { useQuery } from '@tanstack/react-query';
import { getProductListByReorder } from '../../Helpers/Queries';
import { useState } from 'react';
import Pagination from "@choc-ui/paginator";
import NotFoundReorder from '../NotFound/NotFoundReorder';
import Banner from '../Callouts/Banner';
import { CustomToast } from '../../Helpers/AddToast'

function Reorder() {
  const { addToast } = CustomToast();
    let products = []
    const [page, setPage] = useState(1)
    const { isLoading, isError, data } = useQuery({
        queryKey: ['productListByReorder', page],
        queryFn: ()=>getProductListByReorder(page),
        keepPreviousData : true
    })

    useEffect(() => {
      window.scrollTo({top: 0})
    }, [page])
    

    if(isError){
      addToast({title: "Oh No!", message: "An error occured loading the product list.", type: "error"})
    }

    if(isLoading){
        return (
            <Container maxW='container.xl' bg='white' py={5} minH={'100%'} color='black'>
                <Progress size='lg' colorScheme={'blue'} isIndeterminate />
            </Container>
        )
    }

    if(!isLoading && !isError){
        if(data.data.products.length === 0){
          return <NotFoundReorder />
        }
        products = data.data.products.map((val)=>{
            return <TileLayout key={val.UID} product={val} />
        })
    }

  return (
    <>
        <VStack
            minH={'calc(100% - 100px)'}
            spacing={2}
            py={5}
            >
            <Container maxW='container.xl' bg='white' py={5} minH={'100%'} color='black'>
                <Banner pageName="Reorder all your previously purchased items from one spot" backgroundImage="https://images.unsplash.com/photo-1585421514738-01798e348b17?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80" />
            </Container>
            <Container maxW='container.xl' bg='white' h={'100%'} py={5} minH={'100%'} color='black'>
                <SimpleGrid columns={[1, null, 2, 3]} spacing='10px'>
                {products}
                </SimpleGrid>
            </Container>
            <Flex
      w="full"
      p={50}
      alignItems="center"
      justifyContent="center"
    >
      <Pagination
        defaultCurrent={1}
        colorScheme={'blue'}
        defaultPageSize={50}
        total={data.data.count}
        paginationProps={{
          display: "flex",
        }}
        current={page}
        onChange={(page) => setPage(page)}
      />
    </Flex>
        </VStack>
    </>
  );
}

export default Reorder;